import React, { useState, useEffect, useCallback } from 'react'

import { Row, Col, Popover } from 'antd'
import moment from 'moment'
import { Translate } from 'react-localize-redux'
import { useSelector } from 'react-redux'
import { NavLink, useHistory } from 'react-router-dom'
import styled from 'styled-components'

import StatusComponent from './StatusComponent'
import AddWhite from '../../../assets/icons/add_white.svg'
import { GetDeparturesList } from '../../../infra/requests/DepartureRequests'
import { GetAllWarehousesDestiny } from '../../../infra/requests/LogisticsRequests'
import BaseButton from '../../../shared/components/buttons/BaseButton'
import SearchInput from '../../../shared/components/inputs/SearchInput'
import SelectInput from '../../../shared/components/inputs/SelectInput'
import BaseTable from '../../../shared/components/table/BaseTable'
import { ConstructQuery, ConstructSort } from '../../../shared/logic/queries/EndpointQueries'
import ActiveTranslation from '../../../shared/logic/translations/ActiveTranslation'
import { TableTextColor, PrimaryButtonColor } from '../../../shared/styles/_colors'
import { Margin } from '../../../shared/styles/BasicStyles'

const DropMenu = styled.div`
  display: inline-block;
  padding: 0 10px;
`

const MenuLink = styled(NavLink)`
  display: block;
  margin-bottom: 10px;
  cursor: pointer;
  color: ${TableTextColor};
  &:hover {
    color: ${PrimaryButtonColor};
  }
`

const PAGE_SIZE = 20

const getOrderType = (data) => {
  if (data.orderB2BId) return 'b2b'
  if (data.orderId && data.isB2B2COrder) return 'b2b2c'
  if (data.orderId && !data.isB2B2COrder) return 'b2c'
  return null
}

const DeparturesList = () => {
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [rows, setRows] = useState([])
  const [page, setPage] = useState(1)
  const [filters, setFilters] = useState({})
  const [total, setTotal] = useState(0)
  const [warehouses, setWarehouses] = useState([])
  const [sort, setSort] = useState(null)
  const { departureTypes, clients } = useSelector((state) => state.info)

  const updateTable = useCallback(async (values = filters, newSort = sort) => {
    setLoading(true)
    try {
      const { data } = await GetDeparturesList(page, PAGE_SIZE, ConstructQuery(values), ConstructSort(newSort))
      setRows(data?.items || [])
      setTotal(data?.totalItems || 0)
    } catch (error) {
      console.warn(error)
    } finally {
      setLoading(false)
    }
  }, [page, PAGE_SIZE, filters, sort])

  useEffect(() => {
    const fetchWarehouses = async () => {
      try {
        const { data, success } = await GetAllWarehousesDestiny()
        if (success) setWarehouses(data?.items || [])
      } catch (error) {
        console.warn(error)
      }
    }
    fetchWarehouses()
  }, [])

  useEffect(() => {
    updateTable()
  }, [page, sort, filters])

  const onChangePagination = useCallback((newPage) => {
    setPage(newPage)
  }, [updateTable, filters, sort])

  const onChangeSort = useCallback((newSort) => {
    setSort(newSort)
  }, [updateTable, filters])

  const handleSetFilters = useCallback((newFilters) => {
    setPage(1)
    setFilters((currentFilters) => ({
      ...currentFilters,
      ...newFilters
    }))
  }, [updateTable])

  const getColumns = () => [
    {
      title: <Translate id='ID' />,
      type: 'ordered',
      sorter: true,
      dataIndex: 'movementId'
    },
    {
      title: <Translate id='CLIENT' />,
      type: 'ordered',
      sorter: true,
      dataIndex: 'b2bclient'
    },
    {
      title: 'Order Nº',
      type: 'text',
      dataIndex: 'orderB2BIdString',
      render: (value, data) => {
        const orderType = getOrderType(data)

        if (!orderType) return ''

        const isB2B = orderType === 'b2b'
        const id = isB2B ? data.orderB2BId : data.orderId
        const displayValue = isB2B ? data.orderB2BIdString : id
        const orderLink = `/orders/${orderType}/${id}`
        return (
          <a
            href={orderLink}
            target='_blank'
            rel='noopener noreferrer'
            onClick={(e) => e.stopPropagation()} // prevent click event on row
          >
            {displayValue}
          </a>
        )
      }
    },
    {
      title: <Translate id='DEPARTURE_TYPE' />,
      type: 'ordered',
      sorter: true,
      dataIndex: 'movementTypeId',
      render: (value, data) => <ActiveTranslation value={data?.movementTypeTranslation} tag='name' />
    },
    {
      title: <Translate id='DEPARTURE_DATE' />,
      dataIndex: 'date',
      type: 'ordered',
      sorter: true,
      render: (value) => (value ? moment.utc(value).format('DD/MM/YYYY') : 'N/A')
    },
    {
      title: 'Warehouse',
      type: 'ordered',
      sorter: true,
      dataIndex: 'warehouseId',
      render: (value, data) => data?.warehouse
    },
    {
      title: <Translate id='MOV_QTY' />,
      type: 'text',
      dataIndex: 'quantity',
      render: (value) => Math.abs(value)
    },
    {
      title: <Translate id='TOTAL' />,
      type: 'text',
      dataIndex: 'total',
      render: (value) => (value ? `${Math.abs(value.toFixed(2))}€` : '0€')
    },
    {
      title: <Translate id='STATUS' />,
      type: 'text',
      dataIndex: 'stage',
      render: (value, data) => <StatusComponent info={data?.movementStatusTranslation} />
    }
  ]

  return (
    <>
      <Row gutter={[24, 16]}>
        <Col xs={24} sm={12} lg={6}>
          <Popover
            content={
              <DropMenu>
                <MenuLink to='/logistics/departures/waybill-kits/new'><Translate id='WAYBILL_KITS' /></MenuLink>
                <MenuLink to='/logistics/departures/transfers/new'><Translate id='WH_TRANSFER' /></MenuLink>
              </DropMenu>
            }
            placement='rightTop'
            trigger='click'
          >
            <BaseButton auto imagePath={AddWhite}>
              <Translate id='CREATE_NEW_DEPARTURE' />
            </BaseButton>
          </Popover>
        </Col>
        <Col xs={24} md={12} lg={4}>
          <SearchInput
            auto
            input={{
              value: filters.Search,
              onChange: (e) => {
                setPage(1)
                handleSetFilters({ ...filters, Search: e }, 300)
              }
            }}
          />
        </Col>
        <Col xs={24} md={12} lg={4}>
          <SelectInput
            input={{
              value: filters.movementType,
              onChange: (value) => {
                setPage(1)
                handleSetFilters({ ...filters, movementType: value }, 0)
              }
            }}
            data={departureTypes}
            placeholder={<Translate id='DEPARTURE_TYPE' />}
            dataKey='movementTypeId'
            dataLabel='movementTypeTranslation'
          />
        </Col>
        <Col xs={24} md={12} lg={4}>
          <SelectInput
            input={{
              value: filters.warehouse,
              onChange: (value) => {
                setPage(1)
                handleSetFilters({ ...filters, warehouse: value }, 0)
              }
            }}
            data={warehouses}
            placeholder={<Translate id='WAREHOUSE' />}
            dataKey='warehouseId'
            dataLabel='name'
          />
        </Col>
        <Col xs={24} md={12} lg={6}>
          <SelectInput
            input={{
              value: clients.b2bclientId,
              onChange: (value) => {
                setPage(1)
                handleSetFilters({ ...filters, customer: value }, 0)
              }
            }}
            data={clients}
            placeholder={<Translate id='CLIENTS' />}
            dataKey='b2bclientId'
          />
        </Col>
      </Row>
      <Margin size={15} />
      <Row>
        <Col xs={24}>
          <BaseTable
            rowKey='movementId'
            datasource={rows}
            columns={getColumns()}
            sortCallback={onChangeSort}
            pagination={{
              render: true,
              page,
              pageSize: PAGE_SIZE,
              total,
              onChangePagination
            }}
            onClickRow={(row) => {
              const { isB2B2COrder, movementId, movementTypeId } = row
              if (movementTypeId === 11) {
                history.push(`/logistics/departures/waybill-kits/${movementId}`)
              }
              if (movementTypeId === 4) {
                history.push(`/logistics/departures/transfers/${movementId}`)
              }
              if (movementTypeId === 16) {
                const subPath = isB2B2COrder ? 'b2b2c' : 'b2c'
                history.push(`/orders/${subPath}/${row.orderId}`)
              }
            }}
            loading={loading}
          />
        </Col>
      </Row>
    </>
  )
}

export default DeparturesList
